import dynamic from 'next/dynamic';

const pages = {
  'home.HomePage': dynamic(() => import('./STWWHomePage')),
  'home.BSHomePage': dynamic(() => import('./STWWHomePage')),
  'flex.FlexPage': dynamic(() => import('./FlexPage')),
  'base.LinkPage': dynamic(() => import('./LinkPage')),
  'bonuswelt.BonusweltListingPage': dynamic(
    () => import('./BonusweltListingPage'),
  ),
  'forms.FormPage': dynamic(() => import('./FormPage')),
  NotFoundPage: dynamic(() => import('./NotFoundPage')),
  PureHTMLPage: dynamic(() => import('./PureHtmlPage')),
  PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
};

export type Pages = keyof typeof pages;

export default pages;
